import React, { FC } from 'react';
import { Icons } from '../Icon';
import * as SC from './styled';
import { ScoreSize } from './styled';
export enum Scores {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}
export const icons = {
  [Scores.A]: Icons.scoreA,
  [Scores.B]: Icons.scoreB,
  [Scores.C]: Icons.scoreC,
  [Scores.D]: Icons.scoreD,
  [Scores.E]: Icons.scoreE
};
export type ScoreProps = {
  className?: string;
  value?: Scores;
  size?: ScoreSize;
};
const Score: FC<ScoreProps> = ({
  className,
  value,
  size
}) => {
  const icon = icons?.[value ?? Scores.A] ?? icons[Scores.A];
  return <SC.Score className={className} $size={size}>
      <SC.ScoreIcon icon={icon} />
    </SC.Score>;
};
export default Score;