import React, { FC } from 'react';
import { TagProps } from '../../atoms/Tag';
import { Icons } from '../../atoms/Icon';
import { Scores } from '../../atoms/Score';
import { ContentCardProps } from '../ContentCard';
import * as SC from './styled';
export type RecipeCardProps = ContentCardProps & {
  brandName?: string;
  tags?: TagProps[];
  score?: Scores;
  duration?: string;
  alwaysShowScore?: boolean;
};
const RecipeCard: FC<RecipeCardProps> = ({
  brandName,
  tags,
  score,
  duration,
  alwaysShowScore,
  ...cardProps
}) => {
  return <SC.Card {...cardProps} $alwaysShowScore={alwaysShowScore}>
      <SC.Spacer />
      {tags && <SC.Tags>
          {tags?.map((tag, i) => <SC.Tag key={i} {...tag} />)}
        </SC.Tags>}
      {(duration || score) && <SC.RecipeInfos>
          {duration && <SC.Duration>
              <SC.DurationIcon icon={Icons.timer} />
              {duration}
            </SC.Duration>}
          {score && duration && <SC.RecipeInfosSeparator />}
          {score && <SC.Score value={score} />}
        </SC.RecipeInfos>}
      {brandName && <SC.BrandName>{brandName}</SC.BrandName>}
    </SC.Card>;
};
export default RecipeCard;