import React, { FC } from 'react';
import { TagProps } from '../../atoms/Tag';
import { ContentCardProps } from '../ContentCard';
import * as SC from './styled';
export type ArticleCardProps = ContentCardProps & {
  tags?: TagProps[];
};
const ArticleCard: FC<ArticleCardProps> = ({
  tags,
  ...cardProps
}) => {
  return <SC.Card {...cardProps}>
      {tags && <SC.Tags>
          {tags?.map((tag, i) => <SC.Tag key={i} {...tag} />)}
        </SC.Tags>}
    </SC.Card>;
};
export default ArticleCard;