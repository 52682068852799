import React, { FC } from 'react';
import { TagProps } from '../../atoms/Tag';
import { ContentCardProps } from '../ContentCard';
import { CardVariant } from '../ContentCard/styled';
import * as SC from './styled';
export type CheeseCardProps = ContentCardProps & {
  tags?: TagProps[];
  text?: string;
};
const CheeseCard: FC<CheeseCardProps> = ({
  tags,
  text,
  ...cardProps
}) => {
  return <SC.Card {...cardProps} $tiny={cardProps.variant === CardVariant.Tiny ? true : false}>
      <SC.Content>
        {tags && <SC.Tags $tiny={cardProps.variant === CardVariant.Tiny ? true : false}>
            {tags?.map((tag, i) => <SC.Tag key={i} {...tag} />)}
          </SC.Tags>}
        {text && <SC.Text $tiny={cardProps.variant === CardVariant.Tiny ? true : false}>
            {text}
          </SC.Text>}
      </SC.Content>
    </SC.Card>;
};
export default CheeseCard;